// FADE TO TOP
var $animation_elements = $('.animated');
var $window = $(window);

function check_if_in_view() {
    var window_height = $window.height();
    var window_top_position = $window.scrollTop();
    var window_bottom_position = (window_top_position + window_height);

    $.each($animation_elements, function() {
        var $element = $(this);
        var element_height = $element.outerHeight();
        var element_top_position = $element.offset().top;
        var element_bottom_position = (element_top_position + element_height);

        //check to see if this current container is within viewport
        if ((element_bottom_position >= window_top_position) &&
            (element_top_position <= window_bottom_position)) {
            $element.addClass('active');
        }
        //else {
        //$element.removeClass('active');
        //}
    });
}

$window.on('scroll resize', check_if_in_view);
$window.trigger('scroll');

// IMAGE REPLACE - img src to bg css
// function replaceSrc() {

//     $('.js-replace-img').each(function() {

//         var src = $(this).find("img").attr("src");
//         $(this).css("background-image", "url(" + src + ")");
//     });
// }

// jQuery(function() {
//     if ($(".js-replace-img")) {
//         replaceSrc();
//     }
// });


(function() {

    var s = {

        menuState: function() {

            $(this).toggleClass("cross");
            $(".main-nav-wrapper").toggleClass("open");
            $('body').toggleClass("overflow");

        },

        headerHeight: function() {

            if ($(window).scrollTop() > 0) {

                return $('.main-header').height();
            } else {

                return $('.main-header').height() - 190;
            }
        },

        headerPosition: function() {

            var top = $(window).scrollTop();

            if (top > 100) {

                $(".main-header").addClass("min");
                $(".sub-nav").addClass("min");
            } else {

                $(".main-header").removeClass("min");
                $(".sub-nav").removeClass("min");
            }
        },

        events: function() {
            $(window).scroll(this.headerPosition);
            $("#btnMobile").on("click", this.menuState);
        },

        init: function() {

            this.headerPosition();
            this.events();
        }

    };

    s.init();

})();

// ANCRE
jQuery ( document ).ready ( function($) {

var hash= window.location.hash;

if ( hash == '' || hash == '#' || hash == undefined ) return false;

      var target = $(hash);

      headerHeight = 200;

      target = target.length ? target : $('[name=' + this.hash.slice(1) +']');

      if (target.length) {
        $('html,body').stop().animate({
          scrollTop: target.offset().top - 100 //offsets for fixed header
        }, 'linear');

      }

} );

// ###########################################################################
// SUBNAV NAVIGATION
$("ul > li.menu-item-has-children > a").click(function (e) {
    e.preventDefault();
    var LiParent =$(this).parent().attr('class');
    if(! LiParent.includes("openSub-menu") ){
        $('ul > li.menu-item-has-children > a').parent().removeClass("openSub-menu");
    }
    $(this).parent().toggleClass("openSub-menu");
});

// CLOSE NAV WHEN CLICK ANYWHERE
$(document).on("click", function (event) {
    var $trigger = $(".menu-item-has-children");
    if ($trigger !== event.target && !$trigger.has(event.target).length) {
        $(".menu-item-has-children").removeClass("openSub-menu");
    }
});

// BTN Category
jQuery('.cat-action').click(function(e) {
  e.preventDefault();
  jQuery('.cat-action').toggleClass('active');
  jQuery('.search-wrapper .category ul').toggleClass('active');
});


// SCROLL TO ANCHOR PRODUCT
jQuery(".link-ancre-product").click(function(e) {
    var aid = $(this).attr("href");
    //$('html,body').animate({scrollTop: $(aid).offset().top - 80},'slow');
    $('html,body').animate({
    scrollTop: $(aid).offset().top
    }, 'linear');
});

// BTN SEARCH
jQuery('#search-btn').click(function(e) {
  e.preventDefault();
  jQuery('.search-desktop').toggleClass('open');
});

// BTN FILTRES
jQuery('#btnMobileFiltre').click(function(e) {
  e.preventDefault();
  jQuery('.aside-product').toggleClass('open');
  jQuery('#btnMobileFiltre').toggleClass('cross');
  jQuery('body').toggleClass('overflow');
});

$('.slider-wrapper-home').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  // autoplay: true,
  autoplay: false,
  autoplaySpeed: 4000,
  speed: 1000,
  fade: true,
  cssEase: 'linear',
  infinite: true,
});

$('.products.home').slick({
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  infinite: true,

  responsive: [

    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        infinite: true,
      }
    },

    {
      breakpoint: 990,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        infinite: true,
      }
    }
  ]

});

$('.category-catalogue .category-content').slick({
  dots: false,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,

  responsive: [

    {
      breakpoint: 1280,
      settings: {
        infinite: true,
        centerMode: false,
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    },

    {
      breakpoint: 760,
      settings: {
        dots: false,
        arrows: true,
        infinite: true,
        centerMode: false,
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    }
  ]
});

$('.home .news-grid').slick({
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 2000,
  infinite: true,
  responsive: [
    {
        breakpoint: 9999,
        settings: "unslick"
    },
    {
      breakpoint: 992,
      settings:
        {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
    },
    {
      breakpoint: 768,
      settings:
        {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
    }
  ]
});
